import { UseQueryOptions, useQuery } from 'react-query'
import { httpService } from 'core/data'
import { HydraResponse } from 'core/types'
import { EntityListItem } from '../types'
import { useState } from 'react'
import { Site } from 'modules/sites'

type EntitiesListRes = HydraResponse<EntityListItem>

type Params = {
  entityTypeId?: number
  siteId?: number
  queryOptions?: UseQueryOptions<any, any, any, any>
  search?: string
  filters?: any
  requestParams?: Record<string, unknown>
}

const QUERY_KEY = 'entities-list'

export default function useEntitiesList(params: Params) {
  const { entityTypeId, queryOptions, search, filters, siteId, requestParams = {} } = params

  const [page, setPage] = useState(1)

  const query = useQuery<EntitiesListRes>(
    [QUERY_KEY, entityTypeId, page, filters, siteId, requestParams],
    async () => {
      const reqParams: any = {
        entityType: entityTypeId ? `/api/entity_types/${entityTypeId}` : undefined,
        page,
        search,
        ...requestParams,
      }

      const headers: any = {}

      if (filters) {
        reqParams.filter = filters
      }

      if (siteId) {
        const site = await httpService.get<Site>(`/sites/${siteId}`).then((res) => res.data)
        headers['x-site-id'] = site.token
      }

      return httpService
        .get<EntitiesListRes>(`/entities`, {
          params: reqParams,
          headers,
        })
        .then((res) => res.data)
    },
    queryOptions
  )

  return {
    data: query.data?.['hydra:member'] || [],
    totalCount: query.data?.['hydra:totalItems'] || 0,
    page,
    setPage,
    isLoading: query.isLoading,
    isFetching: query.isFetching,
  }
}
