import React, { FC } from 'react'
import { Box, CircularProgress, MenuItem, Typography } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import SlugLabel from 'ui/relations-select/slug-label'
import useEntitiesList from 'modules/eav/hooks/use-entities-list'

type Props = {
  siteId: number
  onSelect: (value: string) => void
  value: string[]
  search: string
}

export const SiteItem: FC<Props> = ({ siteId, onSelect, value, search }) => {
  const { isLoading, data, page, totalCount, setPage } = useEntitiesList({
    siteId,
    requestParams: {
      original: true,
    },
    filters: {
      _name: {
        contain: search,
      },
      status: {
        equal: 'publish',
      },
    },
  })

  return (
    <Box>
      {isLoading && (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      )}
      {data?.map((item) => (
        <MenuItem
          key={item['@id']}
          button
          onClick={() => onSelect(item['@id'])}
          selected={value.includes(item['@id'])}
        >
          <Typography noWrap style={{ maxWidth: 250 }} component="div">
            {item.name}
            <SlugLabel entityUrls={item.entityUrls} />
          </Typography>
        </MenuItem>
      ))}
      {!isLoading && (
        <Pagination
          page={page}
          count={Math.round(totalCount / 30)}
          onChange={(e, page) => setPage(page)}
        />
      )}
    </Box>
  )
}
