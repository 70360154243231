import React, { FC } from 'react'
import RelationsField from 'core/form/components/relations-field'
import { List } from './list'
import { useGetActiveSite } from 'modules/sites'
import { AttributeData } from 'modules/new-entity/types'

type Props = {
  attributeData: AttributeData
  name: string
}

const CrossSiteReference: FC<Props> = ({ name, attributeData }) => {
  const activeSite = useGetActiveSite()

  return (
    <RelationsField
      multiple
      label={attributeData.name}
      valueField={'@id'}
      name={name}
      source={'entities'}
      listComponent={<List networkIri={activeSite?.network!} />}
      reqParams={{ network: activeSite?.network }}
    />
  )
}

export default CrossSiteReference
