// Core
import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { Box, Collapse, FormLabel, Grid, Icon, IconButton, Tooltip } from '@material-ui/core'
// Components
import FormControl from '../form-control'
import GroupWrapper from '../group-wrapper'
import GroupError from '../group-error'
// Hooks
import useGroupErrors from '../../hooks/use-group-errors'
// Types
import { AttributeData, TOptions, EavResourceType } from 'modules/new-entity/types'
// Utils
import clsx from 'clsx'
// Styles
import useStyles from './group.styles'

type TProps = {
  name: string
  data: AttributeData
  options: TOptions
  selfType: 'entity' | 'widget'
  resourceType: EavResourceType
  disabled?: boolean
  required?: boolean
}

const Group: FC<TProps> = (props) => {
  const classes = useStyles()
  const { name, data, options, selfType, disabled, resourceType, required } = props

  const [collapseIsOpen, setCollapseIsOpen] = useState(true)

  const collapseHandler = useCallback(() => {
    setCollapseIsOpen(!collapseIsOpen)
  }, [collapseIsOpen])

  const { errorsCount, hasError } = useGroupErrors(name)

  useEffect(() => {
    if (!hasError) return
    setCollapseIsOpen(true)
  }, [hasError])

  if (
    [
      //
      'navigation_item_entity_group',
      'navigation_item_custom_group',
    ].includes(data.slug)
  ) {
    return (
      <Grid container spacing={3}>
        {data.setAttributes?.map((attrItem, index) => {
          return (
            <FormControl
              resourceType={resourceType}
              nested
              selfType={selfType}
              data={attrItem}
              key={`${name}.${attrItem.attribute['@id']}`}
              name={`${name}.${attrItem.attribute['@id']}`}
              // options
              options={options}
              allData={data.setAttributes}
              pathToHierarchicalOptions={`${name}.${attrItem.attribute['@id']}`}
              pathForConditions={`${name}.`}
              disabled={disabled}
            />
          )
        })}
      </Grid>
    )
  }

  return (
    <GroupWrapper hasError={hasError}>
      <div
        className={clsx(classes.header, {
          [classes.headerOpen]: collapseIsOpen,
        })}
      >
        <Box display="flex" alignItems="center">
          <FormLabel required={required}>{data.name}</FormLabel>
          <GroupError count={errorsCount} />
        </Box>
        <Tooltip placement={'left'} title={'collapse all'}>
          <span>
            <IconButton color={'primary'} onClick={collapseHandler}>
              <Icon
                className={clsx({
                  'icon-chevrons-closed': !collapseIsOpen,
                  'icon-chevrons-open': collapseIsOpen,
                })}
              />
            </IconButton>
          </span>
        </Tooltip>
      </div>

      <Collapse className={classes.collapse} unmountOnExit in={collapseIsOpen}>
        <Grid container spacing={3}>
          {data.setAttributes?.map((attrItem, index) => {
            return (
              <FormControl
                resourceType={resourceType}
                nested
                selfType={selfType}
                data={attrItem}
                key={`${name}.${attrItem.attribute['@id']}`}
                name={`${name}.${attrItem.attribute['@id']}`}
                // options
                options={options}
                allData={data.setAttributes}
                pathToHierarchicalOptions={`${name}.${attrItem.attribute['@id']}`}
                pathForConditions={`${name}.`}
                disabled={disabled}
              />
            )
          })}
        </Grid>
      </Collapse>
    </GroupWrapper>
  )
}

export default memo(Group)
